<template>
  <div class="row">
    <div class="full-load" v-if="resetPassowordStatus">
      <h1>
        Sending reset password link to <br />
        <strong>{{ email }}</strong> <br />
        email address
      </h1>
    </div>
    <div class="col-md-12" style="background:white">
      <table class="table table-striped">
        <thead>
          <tr>
            <th colspan="2">
              <input
                type="text"
                v-model="searchInput"
                class="form-control"
                placeholder="Search User"
              />
            </th>
            <th colspan="3"></th>

            <th colspan="2">
              <router-link
                v-if="currentUser.u.is_admin == 0"
                :to="{ name: 'client.user.create' }"
              >
                <div class="btn btn-info" style="float: right">
                  <i class="fa fa-plus"></i> Add User
                </div>
              </router-link>

              <router-link v-else :to="{ name: 'admin.user.create' }">
                <div class="btn btn-info" style="float: right">
                  <i class="fa fa-plus"></i> Add User
                </div>
              </router-link>
            </th>
          </tr>

          <tr>
            <th>Name</th>
            <th>Email</th>
            <th>Username</th>
            <th>Role</th>
            <th>Status</th>
            <th style="    width: 21%;"></th>
          </tr>
        </thead>
        <tbody v-if="!loadingData && filteredUsers.length > 0">
          <tr
            v-for="(item, index) in filteredUsers"
            :key="index"
            v-show="item.id != currentUser.u.id"
          >
            <td>{{ item.name }}</td>
            <td>{{ item.email }}</td>
            <td>{{ item.username }}</td>
            <td>
              <span
                class="text-white badge badge-primary"
                style="cursor:pointer"
                @click="editRole(index)"
                v-if="!item.change_status"
              >
                {{ item.roles }}
              </span>
              <div class="form-group" v-if="item.change_status">
                <select
                  class="form-control"
                  id="exampleFormControlSelect1"
                  v-model="item.new_role"
                >
                  <option value="admin">Admin</option>
                  <option value="staff" selected>Staff</option>
                </select>
                <div class="input-group-append">
                  <label
                    v-if="item.roles != item.new_role"
                    class="input-group-text"
                    style="cursor:pointer"
                    @click="
                      updateUserStatus(
                        index,
                        item.id,
                        item.is_active,
                        item.username,
                        item.new_role,
                        'role'
                      )
                    "
                    for="inputGroupSelect02"
                    ><i class="fa fa-check text-success"></i
                  ></label>
                  <label
                    class="input-group-text"
                    style="cursor:pointer"
                    @click="closeChangeStatusFlag(index)"
                    for="inputGroupSelect02"
                    ><i class="fa fa-times text-danger"></i
                  ></label>
                </div>
              </div>
            </td>
            <td>
              <div class="form-group">
                <span class="switch">
                  <input
                    type="checkbox"
                    v-model="item.is_active"
                    class="switch"
                    :id="'switch-normal' + item.id"
                    @change="
                      updateUserStatus(
                        index,
                        item.id,
                        item.is_active,
                        item.username,
                        item.new_role,
                        'status'
                      )
                    "
                  />
                  <label :for="'switch-normal' + item.id"></label>
                </span>
              </div>
            </td>
            <!-- <td>
              <a @click="sendPassword(item.username, item.email)">
                <i class="fa fa-key text-info ml-3" style="cursor: pointer"></i
              ></a>

             
            </td> -->

            <td style="width:100px">
              <!-- <b-dropdown
                id="dropdown-1"
                text="Action"
                class="m-md-2"
                variant="primary"
              >
                <b-dropdown-item
                  @click="sendPassword(item.username, item.email)"
                  >Reset Password</b-dropdown-item
                >
                <b-dropdown-item @click="setPassword(item.id, item.username)"
                  >Set password</b-dropdown-item
                >
                <b-dropdown-item @click="deleteItem(item.id)"
                  >Delete</b-dropdown-item
                >
              </b-dropdown> -->

              <button
                @click="sendPassword(item.username, item.email)"
                class="btn btn-sm btn-info mr-3"
              >
                Reset password
              </button>

              <button
                @click="setPassword(item.id, item.username)"
                class="btn btn-sm btn-success mr-3 mt-2"
              >
                Set password
              </button>

              <button
                @click="deleteItem(item.id)"
                class="btn btn-sm btn-danger mr-3 mt-2"
                style="cursor: pointer;"
              >
                Delete
              </button>
            </td>
          </tr>
        </tbody>

        <tbody v-if="!loadingData && filteredUsers.length < 1">
          <tr>
            <td colspan="8" class="text-center">No record(s) found</td>
          </tr>
        </tbody>

        <tbody v-if="loadingData">
          <tr>
            <th class="text-center" colspan="10">
              <vDataLoader></vDataLoader>
            </th>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/mutations.type";
import {
  FETCH_B_USER,
  EMAIL_PASSWORD_B_USER,
  RESET_USER_PASSWORD,
  DELETE_B_USER
} from "@/core/services/store/actions.type";
import { mapGetters } from "vuex";
import vDataLoader from "@/components/frontend/spinner.vue";
import { UPDATE_B_USER } from "../../../core/services/store/actions.type";
export default {
  data() {
    return {
      rules: [
        { message: "One lowercase letter required.", regex: /[a-z]+/ },
        { message: "One uppercase letter required.", regex: /[A-Z]+/ },
        { message: "8 characters minimum.", regex: /.{8,}/ },
        { message: "One number required.", regex: /[0-9]+/ },
        { message: "One special character required.", regex: /[!@#$%^&*]+/ }
      ],
      breadCrumbs: [
        {
          id: 1,
          title: "Dashboard",
          route: ""
        },
        {
          id: 2,
          title: "Setting",
          route: ""
        },
        {
          id: 3,
          title: "Users",
          route: ""
        }
      ],
      loadingData: false,
      searchInput: "",
      show_modal: "false",
      resetPassowordStatus: false,
      editrole: false
    };
  },
  components: {
    vDataLoader
  },
  computed: {
    ...mapGetters(["getUsers", "currentUser"]),
    filteredUsers() {
      let tempUser = this.getUsers;
      //process search input
      if (this.searchInput != "" && this.searchInput) {
        tempUser = tempUser.filter(item => {
          return item.name
            .toUpperCase()
            .includes(this.searchInput.toUpperCase());
        });
      }
      return tempUser;
    }
  },
  created() {
    if (this.$route.path == "/admin/users") {
      this.breadCrumbs[0].route = "admin.dashboard";
    } else {
      this.breadCrumbs[0].route = "client.dashboard";
    }
    this.$store.commit(SET_BREADCRUMB, this.breadCrumbs);
    this.getUserData();
  },
  methods: {
    editRole(i) {
      this.filteredUsers[i].change_status = true;
      this.editrole = true;
    },
    closeChangeStatusFlag(index) {
      this.selectedAppId = 0;
      this.filteredUsers[index].change_status = false;
    },
    async setPassword(id = 0, username = "") {
      const { value: password } = await this.$swal.fire({
        title: "Setting new password for user <b>" + username + "</b>",
        input: "password",
        inputPlaceholder: "Enter new password",
        confirmButtonText: "Save",
        cancelButtonText: "Cancel",
        showCancelButton: true,
        showCloseButton: true,
        everseButtons: true,
        inputValidator: value => {
          if (!value) {
            return "Please enter password!";
          } else {
            for (let condition of this.rules) {
              if (!condition.regex.test(value)) {
                return condition.message;
              }
            }
          }
        }
      });

      if (password) {
        this.$store
          .dispatch(EMAIL_PASSWORD_B_USER, {
            user_id: id,
            password: password
          })
          .then(data => {
            this.$toastr.s(data.msg);
            this.$swal.fire(`New Password has been set to user ${username}`);
            // this.closeBtnLoad(ref);
          })
          .catch(err => {
            this.errors = err;
            // console.log(this.errors);
            this.$toastr.e(this.errors.error.password);
            // this.toast.fire(`${this.errors.error.password}`);
            // this.closeBtnLoad(ref);
          });
      }
    },
    loadingButton(ref) {
      const submitButton = this.$refs[ref];
      submitButton.disabled = true;
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");
    },
    closeBtnLoad(ref) {
      const submitButton = this.$refs[ref];
      submitButton.classList.remove(
        "spinner",
        "spinner-light",
        "spinner-right"
      );
      submitButton.disabled = false;
    },
    updateUserStatus(index, id, flag, username, role, status) {
      this.errors = [];
      var flag_ = flag ? false : true;
      var _data = {};
      var text = "";
      if (status == "status") {
        _data = {
          is_active: flag,
          status_: true
        };
        text = "Would you like to change the status of user: " + username + "?";
      } else {
        _data = {
          status_: true,
          roles: role
        };
        text = "Would you like to change the role of user: " + username + "?";
      }
      this.$swal
        .fire({
          title: "Are you sure?",
          text: text,
          confirmButtonText: "Save",
          cancelButtonText: "Cancel",
          showCancelButton: true,
          showCloseButton: true,
          everseButtons: true
        })
        .then(change_status => {
          if (change_status.isConfirmed) {
            this.$store
              .dispatch(UPDATE_B_USER, {
                id: id,
                data: _data
              })
              .then(data => {
                this.$toastr.s(data.msg);
                if (status != "status") {
                  this.filteredUsers[index].change_status = false;
                  this.filteredUsers[index].roles = role;
                } else {
                  this.filteredUsers[index].is_active = flag_;
                }
                this.getUserData();
              })
              .catch(err => {
                this.errors = err;
                // this.closeBtnLoad(ref);
              });
          } else {
            this.getUserData();
          }
        });

      // this.loadingButton(ref);
    },
    sendPassword(username, email) {
      var that = this;
      // var
      var slug = this.currentUser.c.slug;

      if (this.$route.path != "/admin/users") {
        slug = this.$route.params.client_slug;
      }
      that
        .$swal({
          title: "Are you sure to reset password of user: " + username + " ?",
          text: "New Password will be sent to " + email,
          confirmButtonText: "Confirm",
          cancelButtonText: "Cancel",
          showCancelButton: true,
          showCloseButton: true,
          everseButtons: true
        })
        .then(sendPassword => {
          if (sendPassword.isConfirmed) {
            that.email = email;
            that.resetPassowordStatus = true;
            that.$store
              .dispatch(RESET_USER_PASSWORD, {
                email: email,
                client_slug: slug
              })
              .then(data => {
                that.resetPassowordStatus = false;
                this.$toastr.s(data.msg);
                // that.closeBtnLoad(ref_);
              })
              .catch(() => {
                that.resetPassowordStatus = false;
                // that.closeBtnLoad(ref_);
              });
          }
        });
    },
    deleteItem(id) {
      this.$swal({
        title: "Are you sure?",
        text: "Once deleted, you will not be able to recover this User data!",
        buttons: true,
        dangerMode: true,
        showCancelButton: true,
        cancelButtonText: "No",
        confirmButtonText: "Yes"
      }).then(willDelete => {
        if (willDelete.isConfirmed) {
          this.$store.dispatch(DELETE_B_USER, id).then(data => {
            this.$toastr.s(data.msg);
            // this.filteredUsers.splice(index, 1);
            this.getUserData();
          });
        }
      });
    },

    getUserData() {
      var _data = {
        is_client: this.currentUser.u.is_client,
        is_service_provider: this.currentUser.u.is_service_provider
      };
      this.loadingData = true;
      this.$store
        .dispatch(FETCH_B_USER, _data)
        .then(() => {
          this.loadingData = false;
        })
        .catch(() => {
          this.loadingData = false;
        });
    }
  }
};
</script>

<style></style>
